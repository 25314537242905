<template>
	<FormRegistrationRequest />
</template>

<script>
import FormRegistrationRequest from '../components/forms/FormRegistrationRequest.vue'
export default {
	name: 'RegistrationRequest',
	components: { FormRegistrationRequest },
}
</script>
